import { Container, Typography, TextField, IconButton, Box, Grid } from '@mui/material'
import { Send } from '@mui/icons-material'
import { useState, type FunctionComponent } from 'react'
import Section from '../components/Section'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { type Guest } from '../models/guest.model'
import { useGetGuest } from '../hooks/use.get.guest'
import TransitionAlert from '../components/TransitionAlert'
import ConfirmGuestForm from '../components/confirm/ConfirmGuestForm'
import { logEvent } from '../services/analytics'

const Confirm: FunctionComponent = () => {
  const [guest, setGuest] = useState<Guest | null>(null)
  const { isLoading, errorMessage, getGuest } = useGetGuest()
  const [successMessage, setSuccessMessage] = useState('')

  const formik = useFormik({
    initialValues: { code: '' },
    validationSchema: yup.object({
      code: yup.string().required('Debe ingresar código indicado en la invitación')
    }),
    onSubmit: async ({ code }): Promise<void> => {
      logEvent('get_guest', { code })
      const result = await getGuest(code)
      setGuest(result)
    }
  })
  return (
    <Section sx={{ color: 'black' }}>
      <Container sx={{
        mt: 10,
        mb: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
        <Typography align="center" variant="h4" sx={{ mb: 2 }}>
          Confirmar asistencia
        </Typography>
        <Typography align="center" variant="h6">
          Ingresa el código que se encuentra en tu invitación
        </Typography>
        <Typography variant="caption" sx={{ mb: 2 }}>El último día para confirmar es el 22 de enero 2025</Typography>
        {
        !guest
          ? (
            <Box component="form" textAlign="center" noValidate onSubmit={formik.handleSubmit}>
              <Grid container>
                <Grid item xs={0} />
                <Grid item xs={11}>
                  <TextField
                    size="small"
                    fullWidth
                    label="Código de invitación"
                    disabled={isLoading}
                    {...formik.getFieldProps('code')}
                    error={formik.touched.code && Boolean(formik.errors.code)}
                    helperText={formik.touched.code && formik.errors.code} />
                </Grid>
                <Grid item xs={1}>
                  <IconButton type="submit" color="primary" disabled={isLoading}>
                    <Send />
                  </IconButton>
                </Grid>
              </Grid>

              <TransitionAlert open={Boolean(errorMessage)} severity="error" sx={{ mt: 2 }}>
                {errorMessage}
              </TransitionAlert>

            </Box>
            )
          : (
            <ConfirmGuestForm guest={guest} onSuccess={(message: string) => {
              setGuest(null)
              setSuccessMessage(message)
            }} />
            )
      }
        {!guest && <TransitionAlert
          open={Boolean(successMessage)}
          severity="success"
          onClose={() => { setSuccessMessage('') }}>
          {successMessage}
        </TransitionAlert>}
      </Container>
    </Section>
  )
}

export default Confirm
