import { Box } from '@mui/material'
import { type FunctionComponent } from 'react'

export const TypingIndicator: FunctionComponent = () => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 1, mb: 2 }}>
      <Box
        sx={{
          backgroundColor: '#f0f0f0',
          borderRadius: 2,
          px: 2,
          py: 1.5,
          display: 'flex',
          alignItems: 'center',
          gap: 1
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 0.5,
            alignItems: 'center'
          }}
        >
          {[0, 1, 2].map((i) => (
            <Box
              key={i}
              sx={{
                width: 6,
                height: 6,
                backgroundColor: 'text.secondary',
                borderRadius: '50%',
                animation: 'typing 1s infinite',
                animationDelay: `${i * 0.2}s`,
                '@keyframes typing': {
                  '0%, 100%': {
                    transform: 'translateY(0)',
                    opacity: 0.2
                  },
                  '50%': {
                    transform: 'translateY(-4px)',
                    opacity: 0.8
                  }
                }
              }}
            />
          ))}
        </Box>
      </Box>
    </Box>
  )
}
