import { type FunctionComponent } from 'react'
import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, TextField } from '@mui/material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useUpdateGuest } from '../../../hooks/use.update.guest'
import { LoadingButton } from '@mui/lab'
import TransitionAlert from '../../TransitionAlert'
import { type Guest } from '../../../models/guest.model'
import { type UpdateData } from 'firebase/firestore'

interface Props {
  guest: Guest
  onSuccess: (message: string) => void
}
const ConfirmPersonForm: FunctionComponent<Props> = ({ guest, onSuccess }) => {
  const isParent = guest.isFather || guest.isMother
  const { isLoading, errorMessage, updateGuest } = useUpdateGuest()
  const formik = useFormik({
    initialValues: {
      isAttending: guest.guestsMap?.[guest.name] ? 'true' : 'false',
      plusOne: Object.keys(guest.guestsMap ?? {}).length > 1 ? 'true' : 'false',
      plusOneName: Object.keys(guest.guestsMap ?? {}).length > 1 ? Object.keys(guest.guestsMap).find(x => x !== guest.name) : '',
      comment: guest.comment ?? ''
    },
    validationSchema: yup.object({
      isAttending: isParent
        ? yup.string().oneOf(['true'], 'Se debe seleccionar que sí irás al evento')
        : yup.string().required('Debe indicar si va al evento'),
      plusOne: yup.string().required('Debe indicar si lleva acompañante'),
      plusOneName: yup.string().when(['isAttending', 'plusOne'], {
        is: (isAttending: string, plusOne: string) => isAttending === 'true' && plusOne === 'true',
        then: schema => schema.required('Debe ingresar nombre de acompañante'),
        otherwise: schema => schema.notRequired()
      }),
      comment: yup.string().optional()
    }),
    onSubmit: async ({ isAttending, plusOne, plusOneName, comment }) => {
      const updateData: UpdateData<Guest> = {
        code: guest.code,
        guestsMap: {
          [guest.name]: isAttending === 'true'
        },
        comment
      }
      if (plusOne === 'true') {
        (updateData.guestsMap as Record<string, boolean>)[plusOneName as string] = true
      }
      await updateGuest(guest.id, { ...updateData })
      onSuccess('Se ha guardado tu respuesta. Gracias por confirmar!')
    }
  })
  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <FormControl fullWidth>
        <FormLabel>
          ¿Irás al evento?{isParent && ` (Eres ${guest.isFather ? 'padre' : 'madre'} de uno de los novios. Sólo tienes una opción para responder)`}
        </FormLabel>
        <RadioGroup row {...formik.getFieldProps('isAttending')}>
          <FormControlLabel value="true" control={<Radio />} label="Sí" />
          {!isParent && <FormControlLabel value="false" control={<Radio />} label="No" />}
        </RadioGroup>
        <FormHelperText error>{formik.touched.isAttending && formik.errors.isAttending}</FormHelperText>
      </FormControl>

      {formik.values.isAttending === 'true' && !isParent && (
        <FormControl fullWidth>
          <FormLabel>¿Llevarás acompañante?</FormLabel>
          <RadioGroup row {...formik.getFieldProps('plusOne')}>
            <FormControlLabel value="true" control={<Radio />} label="Sí" />
            <FormControlLabel value="false" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      )}

      {formik.values.isAttending === 'true' && formik.values.plusOne === 'true' && (
        <TextField
          sx={{ mt: 1 }}
          label="Nombre acompañante"
          size="small"
          fullWidth
          {...formik.getFieldProps('plusOneName')}
          error={formik.touched.plusOneName && Boolean(formik.errors.plusOneName)}
          helperText={formik.touched.plusOneName && formik.errors.plusOneName} />
      )}

      {formik.values.isAttending === 'true' && (
        <TextField
          sx={{ mt: 1 }}
          label="Observaciones (alergias, mañas u otros)"
          size="small"
          fullWidth
          {...formik.getFieldProps('comment')}
          error={formik.touched.comment && Boolean(formik.errors.comment)}
          helperText={formik.touched.comment && formik.errors.comment} />
      )}

      <LoadingButton
        loading={isLoading}
        sx={{ mt: 1 }}
        size="small"
        type="submit"
        variant="contained">
        Enviar
      </LoadingButton>

      <TransitionAlert open={Boolean(errorMessage)} sx={{ mt: 1 }}>
        {errorMessage}
      </TransitionAlert>
    </form>
  )
}

export default ConfirmPersonForm
