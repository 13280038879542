import { useState } from 'react'
import { configCollection } from '../services/firebase'
import { getDocs, query } from 'firebase/firestore'
import { type Config } from '../models/config.model'

interface UseGetConfig {
  isLoading: boolean
  getConfig: () => Promise<Config>
}

export const useGetConfig = (): UseGetConfig => {
  const [isLoading, setIsLoading] = useState(false)

  const getConfig = async (): Promise<Config> => {
    setIsLoading(true)

    const q = query(configCollection)
    const querySnapshot = await getDocs(q)

    return await new Promise((resolve) => {
      setIsLoading(false)

      if (querySnapshot.empty) {
        resolve({ showConfirmSecction: true, showTableSection: false })
        return
      }

      const [doc] = querySnapshot.docs
      resolve(doc.data() as Config)
    })
  }

  return {
    isLoading,
    getConfig
  }
}
