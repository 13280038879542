import { useState } from 'react'
import { tablesCollection } from '../services/firebase'
import { getDocs } from 'firebase/firestore'
import { type Table } from '../models/table.model'

interface UseGetTables {
  isLoading: boolean
  getTables: () => Promise<Table[]>
}

export const useGetTables = (): UseGetTables => {
  const [isLoading, setIsLoading] = useState(false)

  const getTables = async (): Promise<Table[]> => {
    const querySnapshot = await getDocs(tablesCollection)

    return await new Promise((resolve) => {
      setIsLoading(false)

      if (querySnapshot.empty) {
        resolve([])
        return
      }
      resolve(
        querySnapshot.docs.map(x => ({ id: x.id, ...x.data() }) as Table)
      )
    })
  }

  return {
    isLoading,
    getTables
  }
}
