import { type FunctionComponent } from 'react'
import { Box, Typography, Avatar } from '@mui/material'
import { type ChatMessage } from '../../models/chat.message'
import { AccessTime, Check } from '@mui/icons-material'
import cyborg from '../../static/cyborg.jpeg'

interface Props {
  message: ChatMessage
}

export const MessageBubble: FunctionComponent<Props> = ({ message }) => {
  const isOwn = message.sender === 'user'
  const date = new Date(0)
  date.setUTCSeconds(message.createdAt as number)
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const hoursAsString = `${hours < 10 ? '0' : ''}${hours}`
  const minutesAsString = `${minutes < 10 ? '0' : ''}${minutes}`

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isOwn ? 'row-reverse' : 'row',
        gap: 1,
        mb: 2
      }}>
      {!isOwn && <Avatar src={cyborg} sx={{ width: 32, height: 32 }} />}
      <Box
        sx={{
          maxWidth: '70%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: isOwn ? 'flex-end' : 'flex-start'
        }}>
        {!isOwn && (
          <Typography
            variant="caption"
            sx={{ ml: 1, mb: 0.5, color: 'text.secondary' }}>
            {message.sender}
          </Typography>
        )}
        <Box
          sx={{
            backgroundColor: isOwn ? '#0084ff' : '#f0f0f0',
            color: isOwn ? 'white' : 'black',
            borderRadius: 2,
            px: 2,
            py: 1,
            position: 'relative',
            wordBreak: 'break-word',
            whiteSpace: 'pre-wrap',
            maxWidth: '100%'
          }}>
          <Typography variant="body1">{message.text}</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 0.5 }}>
            {message.createdAt && <Typography
              variant="caption"
              sx={{
                color: isOwn ? 'rgba(255,255,255,0.7)' : 'text.secondary',
                fontSize: '0.7rem'
              }}>
              {`${hoursAsString}:${minutesAsString}`}
            </Typography>}
            {isOwn && (
              <Box sx={{ display: 'inline-flex', ml: 0.5, verticalAlign: 'middle' }}>
                {message.status === 'waiting'
                  ? <AccessTime sx={{ fontSize: 12, color: 'rgba(255,255,255,0.7)' }} />
                  : <Check sx={{ fontSize: 12, color: 'rgba(255,255,255,0.7)' }} />
                    }
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
