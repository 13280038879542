import { Close, QuestionAnswer, Send } from '@mui/icons-material'
import { Avatar, Box, Card, CardActions, CardContent, CardHeader, Fab, IconButton, TextField, Zoom } from '@mui/material'
import { useState, type FunctionComponent } from 'react'
import cyborg from '../static/cyborg.jpeg'
import { type ChatMessage } from '../models/chat.message'
import MesssagesList from './chat/MessagesList'
import { useSendMessage } from '../hooks/use.send.message'
import TransitionAlert from './TransitionAlert'

const Chat: FunctionComponent = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [messages, setMessages] = useState<ChatMessage[]>([])
  const { isLoading, sendMessage, errorMessage } = useSendMessage()

  const triggerSendMessage = async (): Promise<void> => {
    const newMessage: ChatMessage = {
      id: 'new-message',
      threadId: messages[0]?.threadId,
      text: message,
      sender: 'user',
      status: 'waiting'
    }

    setMessage('')
    const newMessages = [...messages, newMessage]
    setMessages(newMessages)

    const result = await sendMessage(newMessage)
    if (!result) {
      setMessage(newMessage.text)
      setMessages(newMessages.slice(0, newMessages.length - 1))
      return
    }
    setMessages([
      ...(newMessages.slice(0, newMessages.length - 1)),
      result.message,
      result.response
    ])
  }
  return (
    <Box sx={{
      margin: 0,
      top: 'auto',
      right: { xs: 20, md: 100 },
      bottom: { xs: 20, md: 40 },
      left: 'auto',
      position: 'fixed',
      zIndex: 3
    }}>
      <Zoom in={!isOpen} unmountOnExit>
        <Fab variant="extended" color="primary" onClick={() => { setIsOpen(true) }}>
          <QuestionAnswer sx={{ mr: 1 }} />
          Chat
        </Fab>
      </Zoom>
      <Zoom in={isOpen} unmountOnExit>
        <Card sx={{
          // Position
          position: 'fixed',
          bottom: '10px',
          right: '20px',
          // Size
          width: '370px',
          // width: "420px",
          height: { xs: '90%', md: '80%' },
          maxWidth: 'calc(100% - 48px)',
          maxHeight: 'calc(100% - 48px)',
          display: 'flex',
          flexDirection: 'column'
        }}>
          <CardHeader
            action={<IconButton onClick={() => { setIsOpen(false) }}>
              <Close />
            </IconButton>}
            avatar={<Avatar src={cyborg} sx={{ width: 60, height: 60 }} />}
            title="rAIhuén"
            subheader="Asistente"/>
          <CardContent sx={{
            flex: 1,
            overflowY: 'auto',
            p: 2,
            backgroundColor: '#f8f9fa',
            '&::-webkit-scrollbar': { width: '8px' },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'rgba(0,0,0,0.05)',
              borderRadius: '4px'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,0.2)',
              borderRadius: '4px',
              '&:hover': { backgroundColor: 'rgba(0,0,0,0.3)' }
            }
          }}>
            <MesssagesList messages={messages} isLoading={isLoading} />
            {errorMessage && <TransitionAlert>{errorMessage}</TransitionAlert>}
          </CardContent>
          <CardActions sx={{ mt: 'auto' }}>
            <TextField
              fullWidth
              label="Escribir mensaje"
              value={message}
              disabled={isLoading}
              onKeyUp={(e) => {
                if (!e.shiftKey && e.key === 'Enter') {
                  triggerSendMessage()
                }
              }}
              onChange={(e) => { setMessage(e.target.value) }}/>

            <IconButton disabled={isLoading} color="primary" onClick={() => { triggerSendMessage() }}>
              <Send />
            </IconButton>
          </CardActions>
        </Card>
      </Zoom>
    </Box>
  )
}

export default Chat
