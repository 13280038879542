import { useState } from 'react'
import { type ChatMessage } from '../models/chat.message'
import { logEvent } from '../services/analytics'
import axios from 'axios'

interface UseSendMessage {
  isLoading: boolean
  errorMessage: string
  sendMessage: (
    message: ChatMessage
  ) => Promise<{ message: ChatMessage, response: ChatMessage } | null>
}

export const useSendMessage = (): UseSendMessage => {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const sendMessage = async (
    params: { threadId?: string, text: string }
  ): Promise<{ message: ChatMessage, response: ChatMessage } | null> => {
    logEvent('chat_message', params)
    setIsLoading(true)
    setErrorMessage('')

    let result: { message: ChatMessage, response: ChatMessage } | null = null
    try {
      result = (
        await axios.post(`${process.env.REACT_APP_SERVER_APP_URL}/chat`, params)
      ).data
    } catch (error) {
      setErrorMessage('Ocurrió un error al tratar de enviar el mensaje')
    }

    setIsLoading(false)
    return result
  }

  return {
    isLoading,
    errorMessage,
    sendMessage
  }
}
