import { Autocomplete, Card, CardContent, CardMedia, CircularProgress, Container, List, ListItem, TextField, Typography } from '@mui/material'
import { useEffect, useState, type FunctionComponent } from 'react'
import Section from '../components/Section'
import { useGetAttendants } from '../hooks/use.get.attendants'
import { type Attendant } from '../models/attendant.model'
import { type Table as TableModel } from '../models/table.model'
import { useGetTables } from '../hooks/use.get.tables'

const Table: FunctionComponent = () => {
  const { isLoading, getAttendants } = useGetAttendants()
  const { isLoading: tablesLoading, getTables } = useGetTables()
  const [attendants, setAttendants] = useState<Attendant[]>([])
  const [tables, setTables] = useState<TableModel[]>([])
  const [selectedAttendant, setSelectedAttendant] = useState<Attendant | null>(null)
  const [selectedTable, setSelectedTable] = useState<TableModel | null>(null)
  const fetchData = async (): Promise<void> => {
    const [att, tab] = await Promise.all([getAttendants(), getTables()])
    att.sort((a, b) => {
      if (a.table > b.table) {
        return 1
      }
      if (a.table < b.table) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      if (a.name < b.name) {
        return -1
      }
      return 0
    })
    setAttendants(att)
    setTables(tab)
  }
  useEffect(() => { fetchData() }, [])
  return (
    <Section sx={{ color: 'black' }}>
      <Container sx={{
        mt: 10,
        mb: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
        <Typography align="center" variant="h4" sx={{ mb: 2 }}>
          Buscar mesa
        </Typography>
        <Typography align="center" variant="h6" sx={{ mb: 2 }}>
          Ingresa tu nombre para buscar tu mesa
        </Typography>
        {
          (isLoading || tablesLoading)
            ? <CircularProgress />
            : <Autocomplete
                disablePortal
                options={attendants}
                getOptionLabel={x => x.name}
                isOptionEqualToValue={
                  (option, value) => option.name === value.name
                }
                groupBy={x => x.table}
                sx={{ width: 300 }}
                onChange={(e, value) => {
                  setSelectedAttendant(value)
                  setSelectedTable(
                    tables.find(x => x.name === value?.table) ?? null
                  )
                }}
                renderInput={
                  (params) => <TextField {...params} label="Nombre" />
                } />
        }
        {
          selectedAttendant &&
            <Card sx={{ my: 3, maxWidth: { xs: '95%', md: '60%' } }}>
              {
                selectedTable && <CardMedia
                  sx={{
                    maxHeight: '300px',
                    maxWidth: '100%',
                    objectFit: 'contain'
                  }}
                  component="img"
                  image={selectedTable.imageUrl} />
              }
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {selectedAttendant.table}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1 }}>
                  {selectedTable?.description}
                </Typography>
                <Typography>Personas en esta mesa:</Typography>
                <List sx={{ mt: 0 }}>
                  {
                    attendants.filter(
                      x => x.table === selectedAttendant.table
                    ).map(x => <ListItem key={x.id}>{x.name}</ListItem>)
                  }
                </List>
              </CardContent>
            </Card>
        }
      </Container>
    </Section>
  )
}

export default Table
