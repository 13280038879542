import { type FunctionComponent, useEffect, useRef, useState } from 'react'
import './App.css'
import Counter from './views/Counter'
import Location from './views/Location'
import Confirm from './views/Confirm'
import Gifts from './views/Gifts'
import Images from './views/Images'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import VerifyPayment from './views/VerifyPayment'
import Navigation from './components/Navigation'
import DressCode from './views/DressCode'
import { useGetConfig } from './hooks/use.get.config'
import { CircularProgress } from '@mui/material'
import { type Config } from './models/config.model'
import Table from './views/Table'
import Chat from './components/Chat'

const App: FunctionComponent = () => {
  const { isLoading, getConfig } = useGetConfig()
  const [config, setConfig] = useState<Config>({ showConfirmSecction: true, showTableSection: false })
  const fetchConfig = async (): Promise<void> => {
    setConfig(await getConfig())
  }
  useEffect(() => { fetchConfig() }, [])

  const sectionRefs = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null)
  ]

  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        (
          <>
            <Navigation
              config={config}
              onNavigate={(indexOfElement) => {
                sectionRefs[indexOfElement].current?.scrollIntoView({ behavior: 'smooth' })
              }} />
            <div ref={sectionRefs[0]}><Counter /></div>
            <div ref={sectionRefs[1]}>{config.showConfirmSecction ? <Confirm /> : <Table />}</div>
            <div ref={sectionRefs[2]}><Location /></div>
            <div ref={sectionRefs[3]}><Gifts /></div>
            <div ref={sectionRefs[4]}><DressCode /></div>
            <Images />
            <Chat />
          </>
        )
      )
    },
    { path: '/payment/:paymentId', element: <VerifyPayment /> }
  ])

  if (isLoading) {
    return <CircularProgress />
  }

  return <RouterProvider router={router} />
}

export default App
