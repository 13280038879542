export enum GuestType {
  PERSON = 'PERSON'
}
export interface Guest {
  id: string
  code: string
  guestsMap: Record<string, boolean>
  name: string
  type: GuestType
  comment: string
  isFather: boolean
  isMother: boolean
}
