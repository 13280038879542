import { BottomNavigation, BottomNavigationAction } from '@mui/material'
import { useState, type FunctionComponent } from 'react'
import { HomeRounded, PlaylistAddCheckRounded, LocationOn, RedeemOutlined, DryCleaning, LocalDining } from '@mui/icons-material'
import { logEvent } from '../services/analytics'
import { type Config } from '../models/config.model'

interface Props {
  onNavigate: (indexOfElement: number) => void
  config: Config
}

const Navigation: FunctionComponent<Props> = ({ onNavigate, config }) => {
  const [currentNavElementIndex, setCurrentNavElementIndex] = useState(0)

  const events = [
    'navigation_counter',
    config.showConfirmSecction ? 'navigation_confirm' : 'navigation_table',
    'navigation_location',
    'navigation_gifts',
    'navigation_dress_code',
    'navigation_table'
  ]

  return (
    <BottomNavigation
      value={currentNavElementIndex}
      onChange={(event, value: number) => {
        logEvent(events[value])
        setCurrentNavElementIndex(value)
        onNavigate(value)
      }}
      showLabels
      sx={{
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: 1000
      }} >
      <BottomNavigationAction label="Inicio" icon={<HomeRounded />} />
      {config.showConfirmSecction
        ? <BottomNavigationAction label="Confirma" icon={<PlaylistAddCheckRounded />} />
        : <BottomNavigationAction label="Mesa" icon={<LocalDining />} />}
      <BottomNavigationAction label="Ubicación" icon={<LocationOn />} />
      <BottomNavigationAction label="Regalo" icon={<RedeemOutlined />} />
      <BottomNavigationAction label="Dress code" icon={<DryCleaning />} />
    </BottomNavigation>
  )
}

export default Navigation
