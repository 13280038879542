import { useEffect, useRef, type FunctionComponent } from 'react'
import { type ChatMessage } from '../../models/chat.message'
import { Box } from '@mui/material'
import { MessageBubble } from './MessageBubble'
import { TypingIndicator } from './TypingIndicator'

interface Props {
  messages: ChatMessage[]
  isLoading: boolean
}
const MesssagesList: FunctionComponent<Props> = ({ messages, isLoading }) => {
  const messagesEndRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [messages])

  return (
    <Box
      sx={{
        flex: 1,
        overflowY: 'auto',
        p: 2,
        backgroundColor: '#f8f9fa'
      }}
      >
      {messages.map((message) => (
        <MessageBubble key={message.id} message={message} />
      ))}
      {isLoading && <TypingIndicator />}
      <div ref={messagesEndRef} />
    </Box>
  )
}

export default MesssagesList
